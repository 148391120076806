<template>
  <AllerNewsRibbonContainer v-bind="$props">
    <template #ribbon="slotProps">
      <AllerNewsRibbon v-bind="slotProps" v-intersect="{ callback: visibilityHandler }" class="bg-yellow-400" list-container-classes="pl-[100px] ml-20">
        <template #preItem>
          <NewsRibbonPreItem type="article-top" />
        </template>
      </AllerNewsRibbon>
    </template>
    <template #headerRibbon="slotProps">
      <Teleport to="#newsRibbonHeaderContainer">
        <div v-if="showInHeader">
          <AllerNewsRibbon v-bind="slotProps" type="header" class="md:pl-15" list-container-classes="-ml-7 pl-7 md:ml-0 md:pl-0">
            <NewsRibbonPreItem #preItem type="header" />
          </AllerNewsRibbon>
        </div>
      </Teleport>
    </template>
  </AllerNewsRibbonContainer>
</template>

<script setup lang="ts">
const showInHeader = useState('newsTickerHeaderActive');

const visibilityHandler = (isVisible: boolean) => {
  showInHeader.value = !isVisible;
};

onBeforeUnmount(() => {
  showInHeader.value = false;
});
</script>